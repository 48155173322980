export const FirebaseConfig = {
	"projectId": "deliva-app-da50e",
	"appId": "1:477653515465:web:39601d52960ac03076e118",
	"databaseURL": "https://deliva-app-da50e-default-rtdb.firebaseio.com",
	"storageBucket": "deliva-app-da50e.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAh0l98wKDovvxcYLLPmkb_llDbNSmQaL8",
	"authDomain": "deliva-app-da50e.firebaseapp.com",
	"messagingSenderId": "477653515465",
	"measurementId": "G-HZ0794W6LL"
};